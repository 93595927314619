import React from 'react'
import ToggleField from '../../../components/formComponents/ToggleField'

export function SettingsNode() {
  return (
    <div className="row mb-2">
      <div className="col-md-12">
        <ToggleField name={'settings.sendZPOWhenContainerArrivedToCustomer'} label={'Posílat ZPO na KZ automaticky po přistavení kontejneru u zákazníka'} />
      </div>
      <div className="col-md-12">
        <ToggleField name={'settings.hasContractWithMetrak'} label={'Zasmluvněno, hradí Metrák'} />
      </div>
      <div className="col-md-12">
        <ToggleField name={'settings.isRecycleStation'} label={'Jedná se o recyklační zařízení'} />
      </div>
    </div>
  )
}
