import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { ProductWasteSelector } from '../../../common/components/ProductWasteSelector/ProductWasteSelector'
import { WasteCategory } from '../../../../graphql/aminTypes'
import { useAvailableResourcesForWasteCollectionQuery } from './queries/get-available-resources-for-waste-collection.query'
import dayjs from 'dayjs'
import { ProductContainerSelector } from '../../../common/components/ContainerSelector/ProductContainerSelector'
import ToggleField from '../../../../components/formComponents/ToggleField'
import FormField, { inputType } from '../../../../components/formComponents/FormField'
import SelectField from '../../../../components/formComponents/SelectField'

export default function WasteCollectionSolid() {
  const formMethods = useFormContext()
  const address = formMethods.watch('customerAddress')
  const availableResourcesForWasteCollectionQR = useAvailableResourcesForWasteCollectionQuery({
    wasteCategory: 'SOLID_WASTE',
    customerLatLng: address ? { lat: parseFloat(address['lat']), lng: parseFloat(address['lng']) } : undefined,
    radiusKm: formMethods.watch('radiusKm'),
    calculationDate: dayjs(formMethods.watch('calculationDate')).format('YYYY-MM-DD'),
    productId: formMethods.watch('productWasteId')
  })
  useEffect(() => {
    formMethods.setValue('containerRentLengthDays', 3)
    formMethods.setValue('allowNearestLargerContainer', 'disabled')
    formMethods.setValue('routingStrategy', 'recommended')
  }, [])

  const containerSelectionOptions = [
    { value: 'disabled', label: 'Neumožnit jiný kontejner' },
    { value: 'capacityOfChosen', label: 'Počítat ale s kapacitou vybraného' },
    { value: 'capacityOfNewOne', label: 'Počítat s plnou kapacitou náhradního' }
  ]
  const routingOptions = [
    { value: 'recommended', label: 'Doporučená trasa' },
    { value: 'fastest', label: 'Nejrychlejší' },
    { value: 'shortest', label: 'Nejkratší' }
  ]

  return (
    <>
      <fieldset className="form-fieldset bg-pink-lt">
        <div className="row">
          <div className="col-md-6">
            <ToggleField name={'showAllAvailableWastes'} label={'Zobrazit všechny existující odpady'} />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-md-6">
            <ProductWasteSelector
              wasteCategory={WasteCategory.SolidWaste}
              label={'Vyberte odpad'}
              showAllAvailableWastes={formMethods.watch('showAllAvailableWastes')}
              availableWastes={availableResourcesForWasteCollectionQR.data?.getAvailableResourcesForWasteCollection.availableWastes as string[]}
            />
          </div>
          <div className="col-md-6">
            <ProductContainerSelector
              label={'Vyberte kontejner'}
              availableContainers={availableResourcesForWasteCollectionQR.data?.getAvailableResourcesForWasteCollection.availableContainers as string[]}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-md-3">
            <FormField type={inputType.number} is2Decimal name={'customContainerCapacity'} label={'Jiná kapacita kontejneru pro výpočet [t]'} valueAsNumber />
          </div>
          <div className="col-md-3">
            <FormField type={inputType.number} name={'containerRentLengthDays'} label={'Délka zapůjčení kontejneru [dni] '} valueAsNumber />
          </div>
          <div className="col-md-4">
            <SelectField
              name={'allowNearestLargerContainer'}
              label={'Možnost použít nejbližší vyšší kontejner dodavatele'}
              optionsData={containerSelectionOptions}
              required
            />
          </div>
          <div className="col-md-2">
            <SelectField name={'routingStrategy'} label={'Hledání trasy'} optionsData={routingOptions} required />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-2 offset-4">
            <button type="submit" className="btn btn-primary btn-block">
              Spočítat ceny
            </button>
          </div>
        </div>
      </fieldset>
    </>
  )
}
